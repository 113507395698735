import { heroImages } from '@/assets';
import {
  IncludePrefixResponsiveSizes,
  includeResponsive,
} from '@utils/imagePrefixes';

export interface IHero {
  id: string;
  title: string | JSX.Element;
  titleStories: string | JSX.Element;
  description: string | JSX.Element;
  descriptionStories: string | JSX.Element;
  cardTitle: string | JSX.Element;
  cardSrc: IncludePrefixResponsiveSizes;
  backgrounds: IncludePrefixResponsiveSizes[];
  titleTag: string;
  altTag: string;
  haveSmall?: boolean;
}

const STORIES_PREFIX = 'Stories/';
const HERO_DESKTOP_PREFIX = 'HeroDesktop/';
const THUMB_PREFIX = `${HERO_DESKTOP_PREFIX}thumbs/`;
const BACKGROUNDS_PREFIX = `${HERO_DESKTOP_PREFIX}backgrounds/`;

export const heroFirstBackground = includeResponsive(
  `${BACKGROUNDS_PREFIX}fiat-toro-2025`,
  'webp'
);
export const heroFirstAltTag =
  'Picape Fiat Toro na cor azul em solo arenoso em movimento levantando poeira do chão com sua velocidade. Ao fundo há um campo de grama verde e uma casa de fazenda. ';
export const heroFirstTitleTag =
  'Nova Fiat Toro 2023 – Siga a picape líder da categoria';
export const storiesFirstBackground = includeResponsive(
  `${STORIES_PREFIX}fiat-toro-2025`,
  'webp'
);
export const storiesHeader = includeResponsive(
  `${STORIES_PREFIX}fiat-toro-logo-campanha`,
  'webp'
);
export const heroHeader = includeResponsive(
  `${STORIES_PREFIX}fiat-toro-logo-campanha`,
  'webp'
);
export const storiesFirstAltTag = '';
export const storiesFirstTitleTag = '';

export const dataHero: IHero[] = [
  {
    id: '1',
    title: 'NOVO MOTOR TURBO DIESEL',
    titleStories: (
      <>
        <em>
          <small>NOVO MOTOR</small>
        </em>
        <br />
        TURBO
        <br /> DIESEL
      </>
    ),
    description: (
      <>
        As versões Volcano e Ranch estão ainda mais fortes! <br />
        Equipadas com o novo Motor 2.2T Diesel, são 200 cv de <br />
        potência e 450 Nm de torque. Já as versões Flex combinam <br />
        performance e eficiência, com o motor Turbo Flex de 176 cv <br />
        potência e 270 Nm de torque.
      </>
    ),
    descriptionStories: (
      <>
        As versões Volcano e Ranch estão ainda mais fortes! <br />
        Equipadas com o novo Motor 2.2T Diesel, são 200 cv de <br />
        potência e 450 Nm de torque. Já as versões Flex combinam <br />
        performance e eficiência, com o motor Turbo Flex de 176 cv <br />
        potência e 270 Nm de torque.
      </>
    ),
    altTag: 'Imagem de motor Turbo Diesel de Fiat Toro em fundo preto.',
    titleTag: 'Novo Motor Turbo Diesel',
    cardTitle: (<>Novo Motor Diesel</>),
    cardSrc: heroImages.turboFlex.thumb,
    backgrounds: [
      //desktop
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-toro-motor-turbo-flex`,
        'webp'
      ),
      //stories
      includeResponsive(`${STORIES_PREFIX}fiat-toro-motor-turbo-flex`, 'webp'),
    ],
    haveSmall: true,
  },
  {
    id: '2',
    title: 'ESPAÇO INTERNO',
    titleStories: (
      <>
        <em>ESPAÇO</em> <br />
        INTERNO
      </>
    ),
    description: (
      <>
        Bancos em couro com ajuste interno
        elétrico e<br />espaço interno realmente
        gigante!
      </>
    ),
    descriptionStories: (
      <>
        Bancos em couro com ajuste interno elétrico e espaço interno realmente
        gigante!
      </>
    ),
    altTag:
      'Foto de cabine do motorista de picape Fiat Toro com bancos em couro marrom e revestimentos em preto.',
    titleTag: 'Espaço Interno Toro',
    cardTitle: 'Espaço Interno',
    cardSrc: heroImages.superTelas.thumb,
    backgrounds: [
      //desktop
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-toro-espaco-interno`,
        'webp'
      ),
      //stories
      includeResponsive(`${STORIES_PREFIX}fiat-toro-espaco-interno`, 'webp'),
    ],
  },
  {
    id: '3',
    title: 'MUITO TECNOLÓGICA',
    titleStories: (
      <>
        <em>MUITO</em> <br />
        TECNOLÓGICA
      </>
    ),
    description: (
      <>
        Central multimídia de incríveis 10.1’’, cluster full digital de
        7’’,<br />ar condicionado digital dual zone,
        alerta de colisão, alerta de<br />saída
        de faixa, carregador sem fio e muito mais.
      </>
    ),
    descriptionStories: (
      <>
        Central multimídia de incríveis 10,1’’, cluster full digital de
        7’’, ar <br />
        condicionado digital dual zone,
        <br /> alerta de colisão, alerta de saída
        <br /> de faixa, carregador sem fio e<br /> muito mais.
      </>
    ),
    altTag:
      'Foto de volante e central multimídia de picape Fiat Toro com acabamento em preto.​',
    titleTag: 'Tecnologia Toro',
    cardTitle: 'Muito Tecnológica',
    cardSrc: heroImages.tecnologia.thumb,
    backgrounds: [
      //desktop
      includeResponsive(`${BACKGROUNDS_PREFIX}fiat-toro-tecnologia`, 'webp'),
      //stories
      includeResponsive(`${STORIES_PREFIX}fiat-toro-tecnologia`, 'webp'),
    ],
  },
  {
    id: '4',
    title: 'CAPACIDADE DE CARGA',
    titleStories: (
      <>
        <em>CAPACIDADE</em>
        <br />
        DE CARGA
      </>
    ),
    description: (
      <>
        Capacidade de carga de até 1 tonelada!<br />Carregue o que precisar, sem sofrer.<br />Isso é Toro!
      </>
    ),
    descriptionStories: (
      <>
        Capacidade de carga de até 1 tonelada!<br />Carregue o que
        precisar, sem sofrer.<br />Isso é Toro!
      </>
    ),
    altTag:
      'Fiat Toro na cor azul vista lateral e traseira. Em sua caçamba estão duas motos, uma azul e uma verde.',
    titleTag: 'Capacidade de Carga Toro',
    cardTitle: 'Capacidade de Carga',
    cardSrc: heroImages.carga.thumb,
    backgrounds: [
      //desktop
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-toro-capacidade-carga`,
        'webp'
      ),
      //stories
      includeResponsive(`${STORIES_PREFIX}fiat-toro-capacidade-carga`, 'webp'),
    ],
  },
  {
    id: '5',
    title: 'FORÇA E CONFORTO',
    titleStories: (
      <>
        <em>FORÇA</em>
        <br />E CONFORTO
      </>
    ),
    description: (
      <>
        Picape de verdade. Caçamba gigante
        com 937 litros <br />e capacidade de
        carga de 1 tonelada (diesel) e<br /> Suspensão Multilink que
        proporciona
        muito<br />mais força,sem abrir mão do
        conforto.
      </>
    ),
    descriptionStories: (
      <>
        Picape de verdade. Caçamba gigante <br />
        com 937 litros e capacidade de
        <br /> carga de 1 tonelada (diesel) e<br /> Suspensão Multilink que
        proporciona
        <br /> muito mais força, sem abrir mão do
        <br /> conforto.
      </>
    ),
    altTag:
      'Fiat Toro em estrada de asfalto na cor azul vista traseira. Em sua caçamba estão diversas caixas sendo transportadas.',
    titleTag: 'Força e Conforto Toro',
    cardTitle: 'Força e Conforto',
    cardSrc: heroImages.conforto.thumb,
    backgrounds: [
      //desktop
      includeResponsive(
        `${BACKGROUNDS_PREFIX}fiat-toro-forca-conforto`,
        'webp'
      ),
      //stories
      includeResponsive(`${STORIES_PREFIX}fiat-toro-forca-conforto`, 'webp'),
    ],
  },
];
