import React, { FC, useContext } from "react"
import styles from "./picape-proud.module.scss"
import { Conditional, CTAButton, SectionElement } from "@/components"
import { dataMenuLabels, links } from "@/data/menu.data"
import scssStyles from "@utils/scssStyles"
import { Context } from "@/context"
import { useRouter } from "next/router"

const PicapeProudSection: FC = () => {
  const router = useRouter()
  const {state, dispatch} = useContext(Context);
  
  return (
    <div className={styles.container}>
      <SectionElement
        id="PicapeProudSection"
        className={scssStyles([styles.box, styles.red])}
      >
        <div className={styles.containerCTA}>
          <CTAButton
            href='#versoes'
            target='_self'
            larger
            text={'Conheça'}
            title={'Conheça'}
            handleClick={(e) => {
              e.preventDefault();
              const versionReference = dataMenuLabels().find((c) => c.slug === "versoes")
              dispatch({
                type: "SCROLL_TO_REFERENCE",
                payload: { reference: versionReference },
              });
              dispatch({
                type: "SET_SLUG_SUFFIXES",
                payload: { reference: {...versionReference, slug: 'ultra'} },
              });
              setTimeout(() => {
                dispatch({
                  type: 'SET_CURRENTLY_VISIBLE_NAV',
                  payload: {
                    view: versionReference,
                    suffix: 'ultra',
                  },
                });
              }, 1000)
            }}
          />
        </div>
      </SectionElement>
    </div>
  )
}

export default PicapeProudSection
