import { ui } from '@/assets';
import {
  ArrowButton,
  Conditional,
  SectionElement,
} from '@/components';
import { VersionsInformation } from '@/components/versions-information';
import { Context } from '@/context';
import { dataMenuLabels } from '@/data/menu.data';
import { useCarsVersionsData } from '@/data/versions.data';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './version.module.scss';
import DataLayer from '@/utils/Datalayer';
import Image from 'next/image';

export const VersionsSectionV3: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [, setCurrCar] = useState('');
  const reference = dataMenuLabels().find((c) => c.slug === 'versoes');
  const [initialIndex, setInitialIndex] = useState<any>(0);
  const [thumbSwiper, setThumbSwiper] = useState<SwiperCore>();

  const cars = useCarsVersionsData();

  const next = () => {
    if (activeIndex < cars.length - 1) {
      setActiveIndex(activeIndex + 1);
      swiperInstance?.slideTo(activeIndex + 1);
    } else {
      setActiveIndex(0);
      swiperInstance?.slideTo(0);
    }
  };

  const previous = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      swiperInstance?.slideTo(activeIndex - 1);
    } else {
      setActiveIndex(cars.length - 1);
      swiperInstance?.slideTo(cars.length - 1);
    }
  };

  useEffect(() => {
    if (
      thumbSwiper &&
      swiperInstance &&
      typeof thumbSwiper?.activeIndex === 'number' &&
      typeof swiperInstance?.activeIndex === 'number'
    ) {
      thumbSwiper.slideTo(swiperInstance.activeIndex);
    }
  }, [
    thumbSwiper,
    thumbSwiper?.activeIndex,
    swiperInstance,
    swiperInstance?.activeIndex,
  ]);

  useEffect(() => {
    setTimeout(() => {
      const index = cars.findIndex(
        (c) => c.slug === state.layout.currentVersion.slug
      );
      if (index) {
        setInitialIndex(index);
        swiperInstance?.slideTo(index);
      } else {
        setInitialIndex(1);
      }
    }, 1);

    if (state.layout.galleryExpanded) {
      swiperInstance?.keyboard.disable();
    } else {
      swiperInstance?.keyboard.enable();
    }
  }, [
    initialIndex,
    activeIndex,
    dispatch,
    state.layout.currentVersion,
    state.layout.galleryExpanded,
    swiperInstance,
    swiperInstance?.keyboard,
  ]);

  const handleButtonClick = (index: number) => {
    swiperInstance?.slideTo(index);
    setActiveIndex(index);
  };

  useEffect(() => {
    setCurrCar(cars[activeIndex]?.model?.shortname);
    const activeColor = cars[activeIndex]?.colors.filter(
      (value) => value.colorActive
    );
    dispatch({
      type: 'SET_COLOR_ACTIVE',
      payload: {
        colorActive: activeColor?.[0]?.colorNameFormatted,
      },
    });

    dispatch({
      type: 'SET_VERSION_PHOTO_SRC',
      payload: { versionPhotoSrc: cars[activeIndex]?.path },
    });
  }, [activeIndex]);

  return (
    <SectionElement
      id="versions"
      navReference={reference}
      className={styles.main}
      onVisibilityChange={(load) => {
        if (state?.layout?.slugSuffixes?.slug) {
          const carIndex = cars.findIndex(car => car.slug === state?.layout?.slugSuffixes?.slug as any)

          setActiveIndex(carIndex)
          swiperInstance?.slideTo(carIndex);

          dispatch({
            type: "SET_SLUG_SUFFIXES",
            payload: { reference: { ...state?.layout?.slugSuffixes, slug: null } },
          });
        }
      }}
    >
      <div className={styles.versions}>
        <div className={styles.container}>
          <div className={styles.head}>
            <h2>
              DESCUBRA QUAL <em>FIAT TORO</em>
              <Conditional notOn="desktop">
                <br />
              </Conditional>{' '}
              COMBINA COM VOCÊ
            </h2>
          </div>
          <nav className={styles.names}>
            <Conditional
              desktop={
                <>
                  {cars.map((car, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: car.slug,
                          elementCategory: 'card',
                          pageSection: 'versao',
                          pageSubsection: car.slug,
                        });
                        handleButtonClick(index);
                      }}
                      className={`${styles.btns} ${activeIndex === index ? styles.active : ''
                        }`}
                    >
                      <div>
                        <em>{car.model.shortname}</em>
                      </div>
                    </button>
                  ))}
                </>
              }
              mobile={
                <Swiper
                  observer
                  observeParents
                  parallax
                  allowTouchMove={false}
                  onSwiper={(e) => {
                    setThumbSwiper(e);
                  }}
                  controller={
                    thumbSwiper ? { control: thumbSwiper } : undefined
                  }
                  centeredSlides
                  slidesPerView={2}
                >
                  {cars.map((car, index) => (
                    <SwiperSlide key={index}>
                      <button
                        key={index}
                        onClick={() => {
                          handleButtonClick(index);
                          DataLayer.clickEvent({
                            element: car.slug,
                            elementCategory: 'card',
                            pageSection: 'versao',
                            pageSubsection: car.slug,
                          });
                        }}
                        className={`${styles.btns} ${activeIndex === index ? styles.active : ''
                          }`}
                      >
                        <div>
                          <em style={{ whiteSpace: 'nowrap' }}>{car.model.shortname}</em>
                        </div>
                      </button>
                    </SwiperSlide>
                  ))}
                </Swiper>
              }
            />
          </nav>
        </div>
        <Swiper
          loop={false}
          onSwiper={setSwiperInstance}
          onSlideChange={(swiper) => {
            const selectedVersion = cars[swiper.activeIndex];
            dispatch({
              type: 'SET_CURRENT_VERSION',
              payload: {
                currentVersion: selectedVersion,
              },
            });
            dispatch({
              type: 'SET_CURRENTLY_VISIBLE_NAV',
              payload: {
                view: reference,
                suffix: selectedVersion.slug,
              },
            });
            setActiveIndex(swiper.activeIndex);
          }}
          pagination
          spaceBetween={20}
          slidesPerView={1}
          centeredSlides={true}
          initialSlide={initialIndex}
        >
          {cars.map((car, i) => {
            return (
              <SwiperSlide key={car.id}>
                <div className={styles.carousel}>
                  <Conditional
                    mobile={
                      <Image
                        alt={
                          useCarsVersionsData(state.layout.colorActive)[
                            activeIndex
                          ]?.src?.alt
                        }
                        title={
                          useCarsVersionsData(state.layout.colorActive)[
                            activeIndex
                          ]?.src?.title
                        }
                        src={
                          useCarsVersionsData(state.layout.colorActive)[
                            activeIndex
                          ]?.src?.mobile?.fullPath2x
                        }
                        width={760}
                        height={494}
                        className={styles.mainImage}
                      />
                    }
                    desktop={
                      <Image
                        alt={
                          useCarsVersionsData(state.layout.colorActive)[
                            activeIndex
                          ]?.src?.alt
                        }
                        title={
                          useCarsVersionsData(state.layout.colorActive)[
                            activeIndex
                          ]?.src?.title
                        }
                        src={
                          useCarsVersionsData(state.layout.colorActive)[
                            activeIndex
                          ]?.src?.desktop?.fullPath2x
                        }
                        width={700}
                        height={455}
                        className={styles.mainImage}
                      />
                    }
                  />
                </div>
              </SwiperSlide>
            );
          })}

          <button
            className={styles.btnPrev}
            onClick={() => swiperInstance?.slidePrev()}
            type="button"
          >
            <div>
              <img src={ui.arrowPrev} alt="Imagem anterior" />
            </div>
          </button>
          <button
            className={styles.btnNext}
            onClick={() => swiperInstance?.slideNext()}
            type="button"
          >
            <div>
              <img src={ui.arrowNext} alt="Próxima Imagem" />
            </div>
          </button>
        </Swiper>
        <VersionsInformation car={cars[activeIndex]} />
      </div>

      <Conditional notOn="mobile">
        <ArrowButton
          previous
          className={styles.previous}
          title={'Anterior'}
          handleClick={() => {
            DataLayer.clickEvent({
              element: 'anterior',
              elementCategory: 'icone',
              pageSection: 'conteudo',
              pageSubsection: 'versoes',
            });
            previous();
          }}
        />
        <ArrowButton
          className={styles.next}
          title={'Próximo'}
          handleClick={() => {
            DataLayer.clickEvent({
              element: 'proximo',
              elementCategory: 'icone',
              pageSection: 'conteudo',
              pageSubsection: 'versoes',
            });
            next();
          }}
        />
      </Conditional>
    </SectionElement>
  );
});
